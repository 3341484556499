export const keycloakURIFromHost = () => {
    const ourHostName = window.location.hostname
    const baseHost = ourHostName.split('.').slice(1).join('.')

    if (window.location !== window.parent.location) {
        // The page is in an iFrames
        return `https://authz.nc.${baseHost}/auth`
    } else {
        return `https://authz.${baseHost}/auth`
    }
}

export const KeycloakConfig = {
    realm: 'staging-preview',
    url: process.env.REACT_APP_OVERRIDE_KEYCLOAK_URI || keycloakURIFromHost(),
    clientId: 'demo-dash',
}
