import React from 'react'
import { Admin, Resource, ListGuesser } from 'react-admin'
import jsonServerProvider from 'ra-data-json-server'
import Dashboard from './Dashboard'
import GWLayout from './GWLayout'
import customRoutes from './customRoutes'
import { createMuiTheme } from '@material-ui/core/'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#eeeeee',
        },
        secondary: {
            main: '#eeeeee',
        },
    },
    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': ['GothamPro'],
            },
        },
    },
})

const dataProvider = jsonServerProvider('http://jsonplaceholder.typicode.com')
const App = () => (
    <Admin
        theme={theme}
        title={'Grey Wizard Tech'}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        appLayout={GWLayout}
        customRoutes={customRoutes}
    >
        <Resource name="users" list={ListGuesser} />
    </Admin>
)

export default App
